// AboutPage.js
import React from 'react';
import MenuBar from './MenuBar';
import BottomBar from './BottomBar';
import './AboutPage.css';

const AboutPage = () => {
    return (
      <div>
        <MenuBar />
          <div className="content">
            <h1>About</h1>
            <p>
              Hey there, I'm Eugene (GEM: 48835423). My journey into Flesh and Blood started around the time of Crucible of War and
              Monarch, sparking my interest in Kano. Having played my fair share of mainstream TCGs, I can confidently say that playing 
              Kano has been a refreshing experience. It's like diving into a whole new world of gameplay with endless possibilities. 
              This inspired me to start creating and sharing WWKD puzzles back in Febryary 2022, while exploring the magnitude of different 
              Kano combos. 
            </p>
            <p>
              These puzzles are all about exploring different strategies and thinking outside the box. The goal of this website is simple: to 
              brew and share these puzzles with the community. I'm always open to new WWKD ideas and contributions, so if you're up for it, 
              shoot me a ping!
            </p>

            <p>Cheers!</p>
        </div>
        <BottomBar />
      </div>
    );
  };
  
  export default AboutPage;
